<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="https://gbapks.com.ru/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="https://gbapks.com.ru/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://gbapks.com.ru/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.ru/blogs/">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <a v-for="(data, i) in bloglist.slice().reverse()" :key="i" class="blog-list-item"
                @click="goto(data.link)" :href="data.link">
                <div class="blog-list-img">
                  <img :src="data.poster" :alt="data.title">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }}
                  </div>
                </div>
              </a>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://gbapks.com.ru/privacy/">Privacy Policy</a> |
            <a href="https://gbapks.com.ru/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbapks.com.ru/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'Backup GB WhatsApp Chat to Google Drive/PC (Restore Backup)',
          poster: require('@/assets/blog-1.webp'),
          desc: "GB WhatsApp is a popular modified version of the official WhatsApp app, offering additional customization options and features. However, because it is an unofficial app, GB WhatsApp cannot access Google Drive directly, which limits its backup options. In this guide, we'll introduce the methods to back up GB WhatsApp chats, transfer them to a new phone, and even transfer them back to the official WhatsApp if needed.",
          link: 'https://gbapks.com.ru/gbwhatsapp-backup-to-google-drive/',
        },
        {
          title: 'YoWhatsApp APK Download Terbaru Januari 2025: Update Terbaru',
          poster: require('@/assets/blog-cv-2.webp'),
          desc: "YoWhatsApp terbaru hadir dengan fitur-fitur menarik yang meningkatkan pengalaman berkirim pesan. Anda dapat menikmati privasi yang lebih baik, seperti menyembunyikan status online dan centang biru. Tema baru dan kemampuan mengirim file hingga 700MB juga tersedia. Jangan lewatkan kesempatan untuk merasakan fitur ini dengan download YoWhatsApp terbaru.",
          link: 'https://gbapks.com.ru/id/yowhatsapp-download-terbaru/',
        },
        {
          title: "How to Save WhatsApp Status 2025 Guide",
          poster: require('@/assets/blog-cv-3.webp'),
          desc: "WhatsApp Status is a popular feature that allows users to share photos, videos, and text updates that disappear after 24 hours. However, WhatsApp doesn't provide an direct way to download status videos. If you've ever wondered how to download WhatsApp status videos",
          link: 'https://gbapks.com.ru/how-to-download-whatsapp-status-video/'
        }
      ],
    };
  },
  mounted () { },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/gbwhatsapp-downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
